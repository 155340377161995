import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';



import Contacts from '../../components/contactform';

import UA_special from '../../pics/UA_specials_LANDSCAPE.jpg'
import ForcedImmigrants from '../../pics/forced_immigrants_scholarship.jpg'

class UAspecial extends React.Component {

	constructor(props) {
		super(props);
		this.state = {format: '1650'};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		this.setState({format: event.target.value});
	}

	render () {
		return <div className="flexContent"
		>
		<Helmet

		title="Special Scholarship for the refugees and forced migrants for the in-person bootcamps"
		meta={[
			{ name: 'description', content: 'Special Scholarship for the refugees and forced migrants for the in-person bootcamps' },
			{ name: 'keywords', content: 'javascript full-stack bootcamp, online javascript bootcamp, online javascript bootcamp in barcelona, online web development bootcamp in barcelona, online react bootcamp' },
			{name:'og:image',content:'https://barcelonacodeschool.com/files/pics/UA_specials_LANDSCAPE.jpg'},
			{ name: 'og:description', content: 'Special Scholarship for the refugees and forced migrants for the in-person bootcamps' },
			{ name: 'og:url', content: 'https://barcelonacodeschool.com/specials/forced-migrants-refugees-special-scholarship/' },
			{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
			]}
		/>
		<h1 className='transparentContainer flex225'><span className='highlighter'>Special Scholarship for the refugees and forced migrants</span> for the in-person bootcamps</h1>

		<div className='fulltimeOnlineBanner'>
		<p className="stamp" style={{'font-size': '15vw','top': 'calc(50% - 25vw)'}}>REFUGEE SCHOLARSHIP</p>
		<img
		className='banners flex600 offsetImage'
		src={ForcedImmigrants} 
		alt='Special Womens Day Scholarships'/>
		</div>
		<div className='transparentContainer flex300'>
		<h2 className='getCurH2'>What is it?</h2>
		<div className='whiteCard'>
		<p>We offer a special <span className='highlighter'>scholarship for the refugees and forced migrants </span> willing to take one of our <span className="highlighter">in-person bootcamps</span> and learn skills needed to start working as a JavaScript developer or UX/Ui Designer.</p>
		<p>If you are a forced migrant or refugee you can take in-person <a className='highlighter' href='/barcelona-code-school/javascript-full-stack-bootcamp/'>JavaScript Full-Stack Bootcamp</a> or in-person <a className='highlighter' href='/ux-design-bootcamp-in-barcelona-code-school/'>UX/UI Design Bootcamp</a> with a <span className='highlighter'>special tuition of 3999€</span> instead of regular 5800€.</p>
		<p>All bootcamps expected outcome is to bring you to the job-ready level. Barcelona Code School also will provide job support to help you to get hired after the graduation and start you career in tech. </p>

		<p>Looking forward to see you among our students!</p>
		</div>
		</div>

		<div className='transparentContainer flex300'>
		<h2 >Apply</h2>
		<div className='whiteCard'>
		<p>Please fill out the application form (it will open in a new window)...</p>
		<a href='https://docs.google.com/forms/d/e/1FAIpQLScdPdushLwPaoiPGqKcWakjiMF2aMYLhP2m1ncvbdbmMyl4xw/viewform?usp=sf_link' target="blank"><button>Apply</button></a>
		<p style={{"marginTop":"1em"}}>We hope this initiative will help some people to get back on track after being forced to move to another country. If you will have any questions please use the contact form below.</p>
		</div>
		</div>



		<div className='transparentContainer flex300' >
		<Contacts title={'Inquiry about forced migrants special scholarship'} subject={'Inquiry about refugees special scholarship'} />
		</div>

		</div>

	}
}

export default UAspecial
